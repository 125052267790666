import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { BottomMenuPage } from '@modules/mobile-bottom-menu/mobile-bottom-menu.component';

/**
 * Represents a component for displaying mobile content pages.
 */
@Component({
  selector: 'app-mobile-content-page',
  standalone: true,
  imports: [NgClass],
  templateUrl: './mobile-content-page.component.html',
  styleUrl: './mobile-content-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileContentPageComponent {
  /**
   * The current page of the bottom menu.
   */
  page = input<BottomMenuPage>();
  /**
   * Determines whether to show the footer.
   */
  showFooter = input<boolean>();
}
