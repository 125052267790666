<div class="mobile-content-page" [ngClass]="'mobile-content-page-' + page()">
  <div class="mobile-content-page-body">
    <ng-content select="[content]"></ng-content>
  </div>
  @if (showFooter()) {
    <div class="mobile-content-page-footer">
      <ng-content select="[footer]"></ng-content>
    </div>
  }
</div>
